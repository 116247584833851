import { DatetimeFilter } from "@/store/DatetimeFilter"
import { QueryParam } from "@/views/query-params"
import { serialize } from "@/composables/use-sync-query-params"
import { encodeDatetimeFilter } from "@/modules/codecs/datetime-filter-codec"

export enum RouteName {
  Login = "Login",
  SignUp = "SignUp",
  OnBoarding = "OnBoarding",
  Apps = "Services",
  HolmesGPT = 'HolmesGPT',
  HolmesChat = 'HolmesChat',
  Clusters = "Cluster Health",
  PlanAndUsage = "Plan & Usage",
  NotFound = "NotFound",
  GlobalTimeline = "Global Timeline",
  AccountAccessRequest = "Account Access Request",
  TrendAnalysis = "TrendAnalysis",
  Error = "Error"
}

/**
 * An abstraction layer for Vue routes.
 */
export class RouterBuilder {
  static getLoginRoute(redirectTo?: string) {
    return { name: RouteName.Login, query: { redirectTo } }
  }

  static getSignUpRoute() {
    return { name: RouteName.SignUp }
  }

  static getOnBoardingRoute(opts?: {
    accountId?: string
    referrer?: string
    mode?: string
  }) {
    return {
      name: RouteName.OnBoarding,
      query: {
        accountToRedo: opts?.accountId,
        referrer: opts?.referrer,
        mode: opts?.mode
      }
    }
  }

  static getAccountAccessRequestRoute({ account }: { account: string}) {
    return {
      name: RouteName.AccountAccessRequest,
      query: {
        // when using account as the first word some functionality intercept it
        // so this is why this naming so weird
        accName: account,
      }
    }
  }


  static getAppsRoute() {
    return { name: RouteName.Apps }
  }

  static getClustersRoute() {
    return { name: RouteName.Clusters }
  }

  static getPlanAndUsageRoute() {
    return { name: RouteName.PlanAndUsage }
  }


  static getGlobalTimelineRoute(opts?: {
    findingId?: string
    dates?: DatetimeFilter
    focusedFindingId?: string
    showResolved?: boolean
  }) {
    return {
      name: RouteName.GlobalTimeline,
      query: {
        finding: opts?.findingId,
        [QueryParam.Dates]: opts?.dates && serialize(encodeDatetimeFilter(opts.dates)),
        [QueryParam.Focus]: opts?.focusedFindingId && serialize(opts.focusedFindingId),
        [QueryParam.ShowResolved]: opts?.showResolved && serialize(opts.showResolved)
      }
    }
  }

  static getTrendAnalysisRoute() {
    return {
      name: RouteName.TrendAnalysis
    }
  }
}
