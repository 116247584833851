export enum QueryParam {
  Dates = "dates",
  Clusters = "clusters",
  Namespaces = "namespaces",
  Apps = "apps",
  Events = "events",
  Names = "names", // Deprecated - don't use
  Priorities = "priorities",
  Grouping = "grouping",
  Focus = "focus",
  DrilldownTarget = "drilldown-target",
  ShowResolved = "show-resolved",
  Filters = "filters",
  Search = "search",
  ChatId = "chat-id",
}
