import VueRouter from 'vue-router'
import { trackPageView } from '@/analytics'
import supabase from '@/store/supabase-client'
import { deserialize } from '@/composables/use-sync-query-params'
import { decodeDatetimeFilter } from "@/modules/codecs/datetime-filter-codec"
import { QueryParam } from "@/views/query-params"
import { queryParamToProp } from "@/router/utils"
import { Service } from '@/store/services/types'
import { Service as ServiceV2 } from '@/store/services/v2/types'

import { Job } from '@/store/jobs'
import { KubeNode } from '@/store/nodes/types'
import { RouteName } from "@/router/router-builder"
import {
  authGuard,
  accountBasedRoutesGuard,
  accountQueryParamCleanupGuard,
  supabaseHashCleanupGuard,
  globalModalGuard
} from "@/router/global-guards"
import { prohibitIfAuthenticatedGuard } from "@/router/route-guards"
import { useLocalStorage } from '@vueuse/core'


const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes: [
    {
      name: RouteName.Error,
      path: '/error',
      component: () => import('@/views/Error.vue'),
      props: (route) => ({
        errorType: route.params.errorType,
        context: {
          ...(route.params.hasOwnProperty('context') && typeof route.params.context === 'string' ? JSON.parse(route.params.context) : {})
        }
      }),
      beforeEnter: (to, _from, next) => {
        if(!to.params.hasOwnProperty('errorType')) {
          next({ name: RouteName.Apps })
          return
        }

        next()
      }
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/LoginView.vue'),
      beforeEnter: prohibitIfAuthenticatedGuard
    },
    {
      name: 'SignUp',
      path: '/signup',
      component: () => import('@/views/SignUpView.vue'),
      beforeEnter: prohibitIfAuthenticatedGuard
    },
    {
      name: 'OnBoarding',
      path: '/on-boarding/',
      component: () => import('@/views/on-boarding/OnBoarding.vue'),
      meta: {
        requiresAuth: true
      },
      beforeEnter: async (to, from, next) => {
        // we can't use pinia in this stage
        const onboardingLocalStorage: any = useLocalStorage('on-boarding', {})
        const cancelOnboarding = () => {
          const isOnboarding = onboardingLocalStorage.value.state?.isOnboarding
          if (isOnboarding === false && from.name !== 'Services' && supabase.accounts.length && !to.query.accountToRedo) {
            return next({ name: 'Services' })
          }
        }

        cancelOnboarding()

        if(!to.query.accountToRedo || (supabase.activeAccount && supabase.activeAccount.id === to.query.accountToRedo))
          return next()
        const account = supabase.accounts
          .find((account) => account.id === to.query.accountToRedo)
        if(account) {
          await supabase.setActiveAccount(account)
          onboardingLocalStorage.value.activeAccount = account
        }
        next()
      },
      children: [
        {
          name: 'NoAccountsFound',
          path: '/no-accounts-found',
          component: () => import('@/views/on-boarding/NoAccountsFoundView.vue'),
        },
        {
          name: 'CreateAccount',
          path: 'create-account',
          component: () => import('@/views/on-boarding/ChooseAccountNameView.vue'),
        },
        {
          name: 'ConfigureAccount',
          path: 'configure-account',
          component: () => import('@/views/on-boarding/ConfigureAccountView.vue'),
        },
        {
          name: 'InstallRobusta',
          path: 'install-robusta',
          component: () => import('@/views/on-boarding/InstallRobustaView.vue'),
        },
        {
          name: 'VerifyInstallation',
          path: 'verify-installation',
          component: () => import('@/views/on-boarding/VerifyInstallationView.vue'),
        },
        {
          name: 'PrometheusAndAlertManager',
          path: 'prometheus-alertmanager',
          component: () => import('@/views/on-boarding/PrometheusAndAlertManagerWizardView.vue'),
        },
        {
          name: 'Survey',
          path: 'survey',
          component: () => import('@/views/on-boarding/Survey.vue'),
        },
      ]
    },
    {
      name: 'Invitation',
      path: '/invitation',
      component: () => import('@/views/on-boarding/Invitation.vue'),
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, _, next) => {
        if(
          to.query.hasOwnProperty('email') &&
          typeof to.query.email === 'string' &&
          to.query.email !== supabase.email
        ) {
          return next({
            name: RouteName.Error,
            params: {
              errorType: 'WRONG_INVITATION_USER',
              context: JSON.stringify({
                currentEmail: supabase.email,
                invitationEmail: to.query.email
              })
            }
          })
        }

        if(supabase.invitations.length === 0)
          return next({ name: 'Services' })

        next()
      }
    },
    {
      name: 'Account Access Request',
      path: '/account-access-request',
      component: () => import('@/views/account-access-request/AccountAccessRequest.vue'),
      meta: {
        requiresAuth: true
      },
      beforeEnter: async (to, _, next) => {
        // If user already has access to the account redirect from the route to apps
        if (supabase.accounts.find(a => a.name === to.query?.accName)) {
          return next({
            path: `/${to.query.accName}`
          })
        }
        // Route without query param, redirect to the start of flow
        if (!to.query?.accName) {
          return next({ path: '/' })
        }

        next()
      }
    },
    {
      path: "/:account?",
      component: () => import('@/views/Auth.vue'),
      meta: {
        requiresAuth: true,
        accountBased: true,
      },
      children: [
        {
          name: RouteName.TrendAnalysis,
          path: 'trends',
          component: () => import('@/features/trend-analysis/TrendAnalysis.vue'),
          props: (route) => {
            return {
              ...queryParamToProp(QueryParam.Dates, route, decodeDatetimeFilter),
            }
          }
        },
        {
          name: 'Services',
          path: 'apps',
          component: () => import('@/views/services/Services.vue'),
          beforeEnter: (route, _, next) => {
            const cluster = deserialize(<string>route.query.cluster)
            const namespace = deserialize(<string>route.query.namespace)
            const kind = deserialize(<string>route.query.type)
            const name = deserialize(<string>route.query.name)
            if (cluster && namespace && kind && name) {
              next({
                name: 'Service Details',
                params: {name, ...route.params},
                query: {cluster, namespace, kind}
              })
              return
            }
            next()
          }
        },
        {
          name: 'Service Details',
          path: 'app/:name',
          component: () => import('@/views/services/ServiceDetails.vue'),
          props: (route) => {
            return {
              cluster: route.query.cluster,
              namespace: route.query.namespace,
              kind: route.query.kind,
              name: route.params.name
            }
          }
        },
        {
          name: RouteName.GlobalTimeline,
          path: 'graphs',
          component: () => import('@/views/GlobalTimeline.vue'),
          props: route => ({
            ...queryParamToProp(QueryParam.Dates, route, decodeDatetimeFilter),
            ...queryParamToProp(QueryParam.Clusters, route),
            ...queryParamToProp(QueryParam.Namespaces, route),
            ...queryParamToProp(QueryParam.Apps, route),
            ...queryParamToProp(QueryParam.Events, route),
            ...queryParamToProp(QueryParam.Names, route), // Backward compatibility. For future use Events param above.
            ...queryParamToProp(QueryParam.Priorities, route),
            ...queryParamToProp(QueryParam.Grouping, route),
            ...queryParamToProp(QueryParam.Focus, route),
            ...queryParamToProp(QueryParam.DrilldownTarget, route),
            ...queryParamToProp(QueryParam.ShowResolved, route),
            ...queryParamToProp(QueryParam.Filters, route),
            ...queryParamToProp(QueryParam.Search, route)
          })
        },
        {
          path: 'holmes',
          component: () => import('@/views/holmes/HolmesView.vue'),
          children: [
            {
              name: RouteName.HolmesGPT,
              path: '',
              component: () => import('@/views/holmes/HolmesMain.vue')
            },
            {
              name: RouteName.HolmesChat,
              path: 'chat',
              component: () => import('@/views/holmes/HolmesChat.vue'),
            },
          ]
        },
        {
          name: 'Jobs',
          path: 'jobs',
          component: () => import('@/views/jobs/Jobs.vue'),
          beforeEnter: (route, _, next) => {
            const cluster = deserialize(<string>route.query.cluster)
            const namespace = deserialize(<string>route.query.namespace)
            const name = deserialize(<string>route.query.name)
            if (cluster && namespace && name) {
              next({
                name: 'Job Details',
                params: {name, ...route.params},
                query: {cluster, namespace}
              })
              return
            }
            next()
          }
        },
        {
          name: 'Job Details',
          path: 'job/:name',
          component: () => import('@/views/jobs/JobDetails.vue'),
          props: (route) => {
            return {
              cluster: route.query.cluster,
              namespace: route.query.namespace,
              name: route.params.name
            }
          }
        },
        {
          name: 'Nodes',
          path: 'nodes',
          component: () => import('@/views/nodes/Nodes.vue'),
          beforeEnter: (route, _, next) => {
            const cluster = deserialize(<string>route.query.cluster)
            const name = deserialize(<string>route.query.name)
            if (cluster && name) {
              next({
                name: 'Node Details',
                params: {name, ...route.params},
                query: {cluster}
              })
              return
            }
            next()
          }
        },
        {
          name: 'Node Details',
          path: 'node/:name',
          component: () => import('@/views/nodes/NodeDetails.vue'),
          props: (route) => {
            return {
              cluster: route.query.cluster,
              name: route.params.name
            }
          }
        },
        {
          name: 'Comparison',
          path: 'comparison',
          component: () => import('@/views/comparison/Comparison.vue')
        },
        {
          name: 'Cluster comparison',
          path: 'compare/cluster',
          component: () => import('@/views/comparison/Comparison.vue'),
          props: {
            compareType: 'cluster'
          }
        },
        {
          name: 'Namespace comparison',
          path: 'compare/namespace',
          component: () => import('@/views/comparison/Comparison.vue'),
          props: {
            compareType: 'namespace'
          }
        },
        {
          name: 'Resource comparison',
          path: 'compare/resources',
          component: () => import('@/views/comparison/ComparisonNew.vue')
        },
        {
          name: RouteName.Clusters,
          path: 'cluster-health',
          component: () => import('@/views/cluster-health/ClusterHealth.vue')
        },
        {
          name: 'Cluster Health Details',
          path: 'cluster-health/:clusterName*',
          component: () => import('@/views/cluster-health/ClusterHealthDetails.vue'),
          props: true
        },
        {
          name: 'Simulate Alert',
          path: 'simulate-alert/:clusterName*',
          component: () => import('@/views/simulate-alert/SimulateAlert.vue'),
          props: true
        },
        {
          name: 'Alerts',
          path: 'alerts',
          component: () => import('@/views/Alerts.vue')
        },
        {
          name: 'Silences',
          path: 'silences',
          component: () => import('@/views/silences/Silences.vue')
        },
        {
          name: 'Create Silence',
          path: 'silences/create',
          component: () => import('@/views/silences/SilenceCreate.vue')
        },
        {
          name: 'Metrics Explorer',
          path: 'metrics-explorer',
          component: () => import('@/views/PrometheusQuery.vue'),
          props: (route) => ({cluster: route.query.cluster, query: route.query.query})
        },
        {
          name: 'KRR',
          path: 'krr',
          component: () => import('@/views/KRR.vue')
        },
        {
          name: 'Insights',
          path: 'insights/:scanId?',
          component: () => import('@/views/Insights.vue'),
          props: true
        },
        {
          name: RouteName.PlanAndUsage,
          path: 'plan-and-usage',
          component: () => import('@/views/PlanAndUsage.vue')
        },
        {
          name: 'Settings',
          path: 'settings',
          component: () => import('@/views/Settings.vue')
        },
        {
          path: 'services',
          redirect: route => {
            const {clusters: qclusters, namespaces: qnamespaces, kind: qkind, name: qname, ...rest} = route.query
            const cluster = <string>JSON.parse(<string>qclusters)[0]
            const namespace = <string>JSON.parse(<string>qnamespaces)[0]
            const kind = <string>qkind
            const name = <string>qname
            return {
              name: 'Service Details',
              params: {name},
              query: {cluster, namespace, kind, ...rest}
            }
          }
        },
        {
          path: '',
          redirect: (route) => ({ name: 'Services', params: { ...route.params } } )
        },
      ]
    },
    {
      name: RouteName.NotFound,
      path: '*',
      component: () => import('@/views/Page404.vue')
    }
  ],
})

router.onError((error) => {
  // Reference: https://medium.com/thinkspecial/chunkloaderror-loading-chunk-failed-gopi-k-kancharla-413efbc21db2
  if(error.name === 'ChunkLoadError' && /Loading.*chunk.*failed/i.test(error.message))
    window.location.reload()
})

// Global before guards
router.beforeEach(authGuard)
router.beforeEach(accountBasedRoutesGuard)
router.beforeEach(accountQueryParamCleanupGuard)
router.beforeEach(supabaseHashCleanupGuard)
router.beforeEach(globalModalGuard)

// Global after guards
router.afterEach((to) => trackPageView(to.path))

export function goToService(service: Service) {
  router.push({
    name: 'Service Details',
    params: {
      account: supabase.accountName,
      name: service.name
    },
    query: {
      cluster: service.cluster,
      namespace: service.namespace,
      kind: service.type
    }
  })
}
export function linkToService(service: ServiceV2) {
  return `/app/${service.name}?cluster=${service.cluster}&namespace=${service.namespace}&kind=${service.kind}`;
}

export function goToJob(job: Job) {
  router.push({
    name: 'Job Details',
    params: {
      account: supabase.accountName,
      name: job.name
    },
    query: {
      cluster: job.cluster_id,
      namespace: job.namespace
    }
  })
}
export function linkToJob(job: Job) {
  return `/job/${job.name}?cluster=${job.cluster_id}&namespace=${job.namespace}`;
}

export function goToNode(node: KubeNode) {
  router.push({
    name: 'Node Details',
    params: {
      account: supabase.accountName,
      name: node.name
    },
    query: {
      cluster: node.cluster_id
    }
  })
}
export function linkToNode(node: KubeNode) {
  return `/node/${node.name}?cluster=${node.cluster_id}`;
}

export function goToAccount(accountName: string) {
  window.location.href = `${window.location.origin}/${accountName}/apps`
}

export default router
