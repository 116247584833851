import supabase from '@/store/supabase-client';
import { useMetricCollector } from '@/composables/use-metric-collector';
import { useUserAgent } from '@/composables/use-user-agent';

const { addMetric } = useMetricCollector();
const { getBrowserType, getOS } = useUserAgent();

export enum ErrorName {
  FAILED_TO_TRACK_USER_SUPABASE_CONNECTION = 'FAILED_TO_TRACK_USER_SUPABASE_CONNECTION',
  FAILED_TO_TRACK_USER_ACCOUNT_CONNECTION = 'FAILED_TO_TRACK_USER_ACCOUNT_CONNECTION',
  FAILED_TO_TRACK_USER_CLUSTER_HOLMES_ENABLED = 'FAILED_TO_TRACK_USER_CLUSTER_HOLMES_ENABLED',
  FAILED_TO_TRACK_USER_ACCOUNT_DATA = 'FAILED_TO_TRACK_USER_ACCOUNT_DATA',

  FAILED_TO_LOGIN_WITH_SSO = 'FAILED_TO_LOGIN_WITH_SSO',
  FAILED_TO_LOGIN_WITH_EMAIL = 'FAILED_TO_LOGIN_WITH_EMAIL',
  FAILED_TO_SIGNUP_WITH_EMAIL = 'FAILED_TO_SIGNUP_WITH_EMAIL',
  FAILED_TO_LOGOUT = 'FAILED_TO_LOGOUT',

  FAILED_TO_GET_SCREENSHOT_UPLOAD = 'FAILED_TO_GET_SCREENSHOT_UPLOAD',
  FAILED_TO_GET_SCREENSHOT_CREATE_SIGNED_URL = 'FAILED_TO_GET_SCREENSHOT_CREATE_SIGNED_URL',
  FAILED_TO_UPDATE_SCREENSHOT = 'FAILED_TO_UPDATE_SCREENSHOT',
  FAILED_TO_DELETE_SCREENSHOT = 'FAILED_TO_DELETE_SCREENSHOT',

  FAILED_TO_FETCH_KRR_RECOMMENDATIONS_BY_NS = 'FAILED_TO_FETCH_KRR_RECOMMENDATIONS_BY_NS',
  FAILED_TO_FETCH_KRR_RECOMMENDATIONS_BY_CLUSTER = 'FAILED_TO_FETCH_KRR_RECOMMENDATIONS_BY_CLUSTER',

  FAILED_TO_FETCH_ACCOUNT_GRAPHS = 'FAILED_TO_FETCH_ACCOUNT_GRAPHS',
  FAILED_TO_FETCH_ACCOUNT_GRAPH_BY_ID = 'FAILED_TO_FETCH_ACCOUNT_GRAPH_BY_ID',

  FAILED_TO_INSERT_HOLMES_EVIDENCE = 'FAILED_TO_INSERT_HOLMES_EVIDENCE',

  FAILED_TO_GET_BILLING_DATA_INFO = 'FAILED_TO_GET_BILLING_DATA_INFO',
  FAILED_TO_GET_MANAGE_SUBSCRIPTION_LINK = 'FAILED_TO_GET_MANAGE_SUBSCRIPTION_LINK',
  FAILED_TO_START_TRIAL_ACCOUNT = 'FAILED_TO_START_TRIAL_ACCOUNT',
  FAILED_TO_GET_CHECKOUT_LINK = 'FAILED_TO_GET_CHECKOUT_LINK',
  FAILED_TO_GET_PODS_USAGE = 'FAILED_TO_GET_PODS_USAGE',
  FAILED_TO_GET_ACCOUNT_BILLING_DATA = 'FAILED_TO_GET_ACCOUNT_BILLING_DATA',

  FAILED_TO_FETCH_ACCOUNT_TYPE = 'FAILED_TO_FETCH_ACCOUNT_TYPE',
  FAILED_TO_FETCH_ALL_USERS_ON_ACCOUNT = 'FAILED_TO_FETCH_ALL_USERS_ON_ACCOUNT',
  FAILED_TO_FETCH_USER_TYPE = 'FAILED_TO_FETCH_USER_TYPE',
  FAILED_TO_FETCH_ALL_USER_REQUESTS = 'FAILED_TO_FETCH_ALL_USER_REQUESTS',
  FAILED_TO_CREATE_JOIN_ACCOUNT_REQUEST = 'FAILED_TO_CREATE_JOIN_ACCOUNT_REQUEST',
  FAILED_TO_DELETE_ACCOUNT_REQUEST = 'FAILED_TO_DELETE_ACCOUNT_REQUEST',
  FAILED_TO_RESPOND_TO_ACCOUNT_REQUEST = 'FAILED_TO_RESPOND_TO_ACCOUNT_REQUEST',
  FAILED_TO_FETCH_USER_ACCOUNTS = 'FAILED_TO_FETCH_USER_ACCOUNTS',
  FAILED_TO_SOFT_DELETE_ACCOUNT = 'FAILED_TO_SOFT_DELETE_ACCOUNT',
  FAILED_TO_RENAME_ACCOUNT = 'FAILED_TO_RENAME_ACCOUNT',

  FAILED_TO_IS_ACCOUNT_RESOURCE_EXISTS = 'FAILED_TO_IS_ACCOUNT_RESOURCE_EXISTS',
  FAILED_TO_REQUEST_ACCOUNT_RESOURCE_CREATE = 'FAILED_TO_REQUEST_ACCOUNT_RESOURCE_CREATE',
  FAILED_TO_REQUEST_ACCOUNT_RESOURCE_UPDATE_BY_ID = 'FAILED_TO_REQUEST_ACCOUNT_RESOURCE_UPDATE_BY_ID',
  FAILED_TO_FETCH_ACCOUNT_RESOURCES = 'FAILED_TO_FETCH_ACCOUNT_RESOURCES',
  FAILED_TO_FETCH_ACCOUNT_RESOURCE_COUNT = 'FAILED_TO_FETCH_ACCOUNT_RESOURCE_COUNT',
  FAILED_TO_FETCH_ACCOUNT_RESOURCE_BY_ID = 'FAILED_TO_FETCH_ACCOUNT_RESOURCE_BY_ID',
  FAILED_TO_DELETE_ACCOUNT_RESOURCE_BY_ID = 'FAILED_TO_DELETE_ACCOUNT_RESOURCE_BY_ID',
  FAILED_TO_DELETE_ACCOUNT_RESOURCES_PERMANENTLY = 'FAILED_TO_DELETE_ACCOUNT_RESOURCES_PERMANENTLY',
  FAILED_TO_ENABLE_GLOBAL_ALERTS = 'FAILED_TO_ENABLE_GLOBAL_ALERTS',

  FAILED_TO_FETCH_ACCOUNT_SETTINGS = 'FAILED_TO_FETCH_ACCOUNT_SETTINGS',

  FAILED_TO_FETCH_AI_APPROVALS = 'FAILED_TO_FETCH_AI_APPROVALS',

  FAILED_TO_FETCH_CLUSTERS_STATUSES = 'FAILED_TO_FETCH_CLUSTERS_STATUSES',
  FAILED_TO_DELETE_CLUSTER = 'FAILED_TO_DELETE_CLUSTER',

  FAILED_TO_FETCH_EVIDENCE_META_DATA_FOR_FINDING = 'FAILED_TO_FETCH_EVIDENCE_META_DATA_FOR_FINDING',
  FAILED_TO_FETCH_EVIDENCE_META_DATA_FOR_MULTIPLE_FINDINGS = 'FAILED_TO_FETCH_EVIDENCE_META_DATA_FOR_MULTIPLE_FINDINGS',
  FAILED_TO_FETCH_FULL_EVIDENCE_BY_ID = 'FAILED_TO_FETCH_FULL_EVIDENCE_BY_ID',
  FAILED_TO_FETCH_EVIDENCE = 'FAILED_TO_FETCH_EVIDENCE',

  FAILED_TO_GET_FEEDBACK_REQUEST = 'FAILED_TO_GET_FEEDBACK_REQUEST',
  FAILED_TO_UPDATE_FEEDBACK_REQUEST = 'FAILED_TO_UPDATE_FEEDBACK_REQUEST',

  FAILED_TO_FETCH_FINDINGS_FROM_NEW_TABLE = 'FAILED_TO_FETCH_FINDINGS_FROM_NEW_TABLE',
  FAILED_TO_FETCH_OLD_FINDINGS_BY_FILTERS = 'FAILED_TO_FETCH_OLD_FINDINGS_BY_FILTERS',
  FAILED_TO_FETCH_FINDINGS_FROM_OLD_TABLE = 'FAILED_TO_FETCH_FINDINGS_FROM_OLD_TABLE',
  FAILED_TO_FETCH_LATEST_JOB_FAILURE_EVENT = 'FAILED_TO_FETCH_LATEST_JOB_FAILURE_EVENT',
  FAILED_TO_FETCH_GROUPED_FIRING_ALERTS = 'FAILED_TO_FETCH_GROUPED_FIRING_ALERTS',

  FAILED_TO_FETCH_GLOBAL_ALERT_TEMPLATES = 'FAILED_TO_FETCH_GLOBAL_ALERT_TEMPLATES',
  FAILED_TO_FETCH_ACCOUNT_ALERT_TEMPLATES = 'FAILED_TO_FETCH_ACCOUNT_ALERT_TEMPLATES',

  FAILED_TO_FETCH_CLUSTERS_STATS = 'FAILED_TO_FETCH_CLUSTERS_STATS',
  FAILED_TO_FETCH_CLUSTERS_STATS_V2 = 'FAILED_TO_FETCH_CLUSTERS_STATS_V2',
  FAILED_TO_FETCH_CLUSTERS_ALERTS = 'FAILED_TO_FETCH_CLUSTERS_ALERTS',

  FAILED_TO_FETCH_JOBS = 'FAILED_TO_FETCH_JOBS',
  FAILED_TO_FETCH_JOB_FINDINGS = 'FAILED_TO_FETCH_JOB_FINDINGS',
  FAILED_TO_DELETE_JOB = 'FAILED_TO_DELETE_JOB',

  FAILED_TO_FETCH_NAMESPACES = 'FAILED_TO_FETCH_NAMESPACES',

  FAILED_TO_FETCH_NODES = 'FAILED_TO_FETCH_NODES',
  FAILED_TO_CORDON_NODE = 'FAILED_TO_CORDON_NODE',
  FAILED_TO_UNCORDON_NODE = 'FAILED_TO_UNCORDON_NODE',
  FAILED_TO_DRAIN_NODE = 'FAILED_TO_DRAIN_NODE',

  FAILED_TO_GET_PAYWALL_MESSAGE = 'FAILED_TO_GET_PAYWALL_MESSAGE',

  FAILED_TO_GET_POPEYE_SCAN_RESULTS_FILTERS = 'FAILED_TO_GET_POPEYE_SCAN_RESULTS_FILTERS',
  FAILED_TO_GET_POPEYE_SCAN_RESULTS_TOTAL = 'FAILED_TO_GET_POPEYE_SCAN_RESULTS_TOTAL',
  FAILED_TO_GET_POPEYE_SCAN_RESULTS = 'FAILED_TO_GET_POPEYE_SCAN_RESULTS',
  FAILED_TO_RUN_POPEYE_SCAN = 'FAILED_TO_RUN_POPEYE_SCAN',

  FAILED_TO_FETCH_PRESETS = 'FAILED_TO_FETCH_PRESETS',
  FAILED_TO_CREATE_PRESET = 'FAILED_TO_CREATE_PRESET',
  FAILED_TO_UPDATE_PRESET = 'FAILED_TO_UPDATE_PRESET',
  FAILED_TO_DELETE_PRESET = 'FAILED_TO_DELETE_PRESET',

  FAILED_TO_FETCH_QUICK_LINKS = 'FAILED_TO_FETCH_QUICK_LINKS',
  FAILED_TO_CREATE_QUICK_LINK = 'FAILED_TO_CREATE_QUICK_LINK',
  FAILED_TO_PATCH_QUICK_LINK = 'FAILED_TO_PATCH_QUICK_LINK',
  FAILED_TO_DELETE_QUICK_LINK = 'FAILED_TO_DELETE_QUICK_LINK',

  FAILED_TO_FETCH_ACCOUNT_SSO = 'FAILED_TO_FETCH_ACCOUNT_SSO',
  FAILED_TO_UPSERT_ACCOUNT_SSO = 'FAILED_TO_UPSERT_ACCOUNT_SSO',

  FAILED_TO_CREATE_PERMISSIONS_GROUP = 'FAILED_TO_CREATE_PERMISSIONS_GROUP',
  FAILED_TO_UPDATE_PERMISSIONS_GROUP = 'FAILED_TO_UPDATE_PERMISSIONS_GROUP',
  FAILED_TO_DELETE_PERMISSIONS_GROUP = 'FAILED_TO_DELETE_PERMISSIONS_GROUP',
  FAILED_TO_GET_PERMISSION_GROUPS = 'FAILED_TO_GET_PERMISSION_GROUPS',

  FAILED_TO_GET_PERMISSION_DEFINITIONS = 'FAILED_TO_GET_PERMISSION_DEFINITIONS',
  FAILED_TO_GET_PERMISSION_ACTIONS = 'FAILED_TO_GET_PERMISSION_ACTIONS',

  FAILED_TO_CREATE_ROLE_PERMISSIONS_GROUP = 'FAILED_TO_CREATE_ROLE_PERMISSIONS_GROUP',
  FAILED_TO_UPDATE_ROLE_PERMISSIONS_GROUP = 'FAILED_TO_UPDATE_ROLE_PERMISSIONS_GROUP',
  FAILED_TO_DELETE_ROLE_PERMISSIONS_GROUP = 'FAILED_TO_DELETE_ROLE_PERMISSIONS_GROUP',
  FAILED_TO_GET_ROLE_PERMISSION_GROUPS = 'FAILED_TO_GET_ROLE_PERMISSION_GROUPS',

  FAILED_TO_FETCH_SCOPES = 'FAILED_TO_FETCH_SCOPES',
  FAILED_TO_CREATE_SCOPE = 'FAILED_TO_CREATE_SCOPE',
  FAILED_TO_UPDATE_SCOPE = 'FAILED_TO_UPDATE_SCOPE',
  FAILED_TO_DELETE_SCOPE = 'FAILED_TO_DELETE_SCOPE',

  FAILED_TO_GET_USER_AUTHORIZED_ACTIONS = 'FAILED_TO_GET_USER_AUTHORIZED_ACTIONS',

  FAILED_TO_GET_RESOURCE_EVENTS_FROM_DB = 'FAILED_TO_GET_RESOURCE_EVENTS_FROM_DB',
  FAILED_TO_GET_RESOURCE_EVENTS_FROM_RELAY = 'FAILED_TO_GET_RESOURCE_EVENTS_FROM_RELAY',
  FAILED_TO_GET_RESOURCE_YAML = 'FAILED_TO_GET_RESOURCE_YAML',

  FAILED_TO_GET_SCANS = 'FAILED_TO_GET_SCANS',
  FAILED_TO_GET_SCAN_BY_ID = 'FAILED_TO_GET_SCAN_BY_ID',
  FAILED_TO_GET_SCAN_RESULTS = 'FAILED_TO_GET_SCAN_RESULTS',
  FAILED_TO_GET_SCAN_RESULTS_FILTERS = 'FAILED_TO_GET_SCAN_RESULTS_FILTERS',
  FAILED_TO_GET_SCAN_RESULTS_TOTAL = 'FAILED_TO_GET_SCAN_RESULTS_TOTAL',
  FAILED_TO_GET_UNIQUE_ISSUES = 'FAILED_TO_GET_UNIQUE_ISSUES',

  FAILED_TO_USE_ROLLOUT_RESTART = 'FAILED_TO_USE_ROLLOUT_RESTART',
  FAILED_TO_USE_SERVICE_RESTARTS = 'FAILED_TO_USE_SERVICE_RESTARTS',
  FAILED_TO_USE_SERVICE_PROBE_FAILURES = 'FAILED_TO_USE_SERVICE_PROBE_FAILURES',
  FAILED_TO_USE_SERVICE_POD_COUNT = 'FAILED_TO_USE_SERVICE_POD_COUNT',
  FAILED_TO_USE_SERVICE_RECENT_ALERTS = 'FAILED_TO_USE_SERVICE_RECENT_ALERTS',
  FAILED_TO_USE_SERVICE_LATEST_DEPLOYMENT = 'FAILED_TO_USE_SERVICE_LATEST_DEPLOYMENT',

  FAILED_TO_FETCH_SERVICES = 'FAILED_TO_FETCH_SERVICES',
  FAILED_TO_ROLLOUT_RESTART = 'FAILED_TO_ROLLOUT_RESTART',

  FAILED_TO_CREATE_SESSION_TOKEN = 'FAILED_TO_CREATE_SESSION_TOKEN',
  FAILED_TO_CREATE_AUTH_TOKEN = 'FAILED_TO_CREATE_AUTH_TOKEN',

  FAILED_TO_FETCH_SINGLE_USER_ACCOUNT = 'FAILED_TO_FETCH_SINGLE_USER_ACCOUNT',

  FAILED_TO_FETCH_USER_SETTINGS = 'FAILED_TO_FETCH_USER_SETTINGS',
  FAILED_TO_FETCH_ALL_USER_SETTINGS = 'FAILED_TO_FETCH_ALL_USER_SETTINGS',
  FAILED_TO_UPDATE_USER_SETTINGS = 'FAILED_TO_UPDATE_USER_SETTINGS',

  FAILED_TO_GET_FINDINGS_WITH_DURATION = 'FAILED_TO_GET_FINDINGS_WITH_DURATION',

  FAILED_TO_INTEGRATES_LACK = 'FAILED_TO_INTEGRATES_LACK',
  FAILED_TO_POST_CHECK_SLACK_MESSAGE = 'FAILED_TO_POST_CHECK_SLACK_MESSAGE',
  FAILED_TO_FETCH_SLACK_STATE = 'FAILED_TO_FETCH_SLACK_STATE',
  FAILED_TO_GET_SLACK_ACCESS_STATE = 'FAILED_TO_GET_SLACK_ACCESS_STATE',
  FAILED_TO_CHECK_ACCOUNT_AVAILABILITY = 'FAILED_TO_CHECK_ACCOUNT_AVAILABILITY',
  FAILED_TO_GET_ACCOUNT_AVAILABILITY = 'FAILED_TO_GET_ACCOUNT_AVAILABILITY',
  FAILED_TO_CREATE_ACCOUNT = 'FAILED_TO_CREATE_ACCOUNT',
  FAILED_TO_CREATE_ACCOUNT_REQUEST = 'FAILED_TO_CREATE_ACCOUNT_REQUEST',
  FAILED_TO_HANDLE_ACCOUNT_CREATION = 'FAILED_TO_HANDLE_ACCOUNT_CREATION',
  FAILED_TO_HANDLE_ACCOUNT_NAME_CREATION = 'FAILED_TO_HANDLE_ACCOUNT_NAME_CREATION',

  FAILED_TO_RUN_PROMETHEUS_QUERY = 'FAILED_TO_RUN_PROMETHEUS_QUERY',

  FAILED_TO_ASK_HOLMES = 'FAILED_TO_ASK_HOLMES',
  FAILED_TO_PARSE_HOLMES = 'FAILED_TO_PARSE_HOLMES',

  FAILED_TO_KRR_SCAN_CLUSTER = 'FAILED_TO_KRR_SCAN_CLUSTER',

  FAILED_TO_FETCH_PODS = 'FAILED_TO_FETCH_PODS',
  FAILED_TO_RESTART_POD = 'FAILED_TO_RESTART_POD',
  FAILED_TO_GET_POD_LOGS = 'FAILED_TO_GET_POD_LOGS',

  FAILED_TO_FETCH_PROMETHEUS_METRICS = 'FAILED_TO_FETCH_PROMETHEUS_METRICS',
  FAILED_TO_FETCH_PROMETHEUS_SERIES = 'FAILED_TO_FETCH_PROMETHEUS_SERIES',

  FAILED_TO_GET_RESOURCE_NAMES = 'FAILED_TO_GET_RESOURCE_NAMES',

  FAILED_TO_FETCH_SILENCES = 'FAILED_TO_FETCH_SILENCES',
  FAILED_TO_REQUEST_CREATE_SILENCE = 'FAILED_TO_REQUEST_CREATE_SILENCE',
  FAILED_TO_REQUEST_DELETE_SILENCE = 'FAILED_TO_REQUEST_DELETE_SILENCE',

  FAILED_TO_GET_YAML = 'FAILED_TO_GET_YAML',

  FAILED_TO_GET_LATEST_ROBUSTA_VERSION = 'FAILED_TO_GET_LATEST_ROBUSTA_VERSION',

  FAILED_TO_POST_ADD_USER_TO_ACCOUNT = 'FAILED_TO_POST_ADD_USER_TO_ACCOUNT',
  FAILED_TO_POST_INVITE_USER_EMAIL_TO_ACCOUNT = 'FAILED_TO_POST_INVITE_USER_EMAIL_TO_ACCOUNT',
  FAILED_TO_POST_CHANGE_ACCOUNT_USER_ROLE = 'FAILED_TO_POST_CHANGE_ACCOUNT_USER_ROLE',
  FAILED_TO_POST_DELETE_USER_FROM_ACCOUNT = 'FAILED_TO_POST_DELETE_USER_FROM_ACCOUNT',

  FAILED_TO_GET_ACCOUNT_FILE_CONFIG = 'FAILED_TO_GET_ACCOUNT_FILE_CONFIG',

  FAILED_TO_AI_CHAT = 'FAILED_TO_AI_CHAT',

  FAILED_TO_SEND_METRIC = 'FAILED_TO_SEND_METRIC',

  FAILED_TO_SUPABASE_LOAD_ACCOUNTS = 'FAILED_TO_SUPABASE_LOAD_ACCOUNTS',
  FAILED_TO_SUPABASE_LOAD_INVITATIONS = 'FAILED_TO_SUPABASE_LOAD_INVITATIONS',
  FAILED_TO_SUPABASE_LOAD_USER_SETTINGS = 'FAILED_TO_SUPABASE_LOAD_USER_SETTINGS',
  FAILED_TO_SUPABASE_SET_USER_SETTING = 'FAILED_TO_SUPABASE_SET_USER_SETTING',

  FAILED_TO_ENABLE_AI = 'FAILED_TO_ENABLE_AI',

  FAILED_TO_ACCEPT_INVITATION = 'FAILED_TO_ACCEPT_INVITATION',
  FAILED_TO_REJECT_INVITATION = 'FAILED_TO_REJECT_INVITATION',

  FAILED_TO_GET_GENERATED_CONFIG = 'FAILED_TO_GET_GENERATED_CONFIG',

  FAILED_TO_CONFIRM_DELETE_CLUSTER = 'FAILED_TO_CONFIRM_DELETE_CLUSTER',

  FAILED_TO_PROMETHEUS_RUN_QUERY = 'FAILED_TO_PROMETHEUS_RUN_QUERY',

  FAILED_TO_HANDLE_INTERACTION_WITH_DEMO = 'FAILED_TO_HANDLE_INTERACTION_WITH_DEMO',
  FAILED_TO_RUN_SCAN_FOR_SELECTED_CLUSTER = 'FAILED_TO_RUN_SCAN_FOR_SELECTED_CLUSTER',

  FAILED_TO_LOAD_FINDING_FROM_PERMALINK = 'FAILED_TO_LOAD_FINDING_FROM_PERMALINK',

  FAILED_TO_DELETE_USER_FROM_ACCOUNT = 'FAILED_TO_DELETE_USER_FROM_ACCOUNT',
  FAILED_TO_CHANGE_USER_ROLE = 'FAILED_TO_CHANGE_USER_ROLE',

  FAILED_TO_SUBMIT_CREDENTIALS = 'FAILED_TO_SUBMIT_CREDENTIALS',

  FAILED_TO_GET_AUTH_AND_RUN_ACTION = 'FAILED_TO_GET_AUTH_AND_RUN_ACTION',
  FAILED_TO_GET_EVIDENCE_FROM_RESPONSE = 'FAILED_TO_GET_EVIDENCE_FROM_RESPONSE',
  FAILED_TO_GET_LAST_EVIDENCE_FROM_RESPONSE = 'FAILED_TO_GET_LAST_EVIDENCE_FROM_RESPONSE',

  FAILED_TO_ACCOUNT_ALERT_TEMPLATE_FETCH = 'FAILED_TO_ACCOUNT_ALERT_TEMPLATE_FETCH',
  FAILED_TO_ACCOUNT_ALERT_TEMPLATE_CREATE = 'FAILED_TO_ACCOUNT_ALERT_TEMPLATE_CREATE',
  FAILED_TO_ACCOUNT_ALERT_TEMPLATE_UPDATEBYID = 'FAILED_TO_ACCOUNT_ALERT_TEMPLATE_UPDATEBYID',
  FAILED_TO_ACCOUNT_ALERT_TEMPLATE_DELETEBYID = 'FAILED_TO_ACCOUNT_ALERT_TEMPLATE_DELETEBYID',
  FAILED_TO_ACCOUNT_ALERT_TEMPLATE_NAME_EXISTS = 'FAILED_TO_ACCOUNT_ALERT_TEMPLATE_NAME_EXISTS',

  FAILED_TO_CREATE_NEW_CHAT = 'FAILED_TO_CREATE_NEW_CHAT',
  FAILED_TO_LOAD_CHAT = 'FAILED_TO_LOAD_CHAT',
  FAILED_TO_LOAD_USER_CHATS = 'FAILED_TO_LOAD_USER_CHATS',
  FAILED_TO_ADD_MESSAGE = 'FAILED_TO_ADD_MESSAGE',
}

/*
import { trackError, ErrorName } from '@/utils/track-error';
trackError(ErrorName.FAILED_TO_);
*/

export function trackError(errorName: ErrorName) {
  const browser = getBrowserType();
  const os = getOS();

  addMetric({
    account_id: supabase.accountId,
    user_id: supabase.userId,
    value: 1,
    name: 'page_errors',
    labels: {
      browser,
      os,
      error_name: errorName,
    },
  });
}
