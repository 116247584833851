import supabase from '@/store/supabase-client'
import { AccountGraph } from '@/components/graph-recomendations/types'
import { trackError, ErrorName } from '@/utils/track-error';

type FetchAccountGraphsOptions = {
  overrideOnly: boolean
}

export async function fetchAccountGraphs(
  accountId: string,
  options?: Partial<FetchAccountGraphsOptions>
): Promise<AccountGraph[]> {
  const accountIds = ['ALL', accountId]
  const request = supabase.client
    .from('AccountGraphs')
    .select('*')
    .in('account_id', accountIds)
    .eq('deleted', false)
    .order('created_at', { ascending: true })

  if(options?.overrideOnly) {
    request.ilike('graph_name', '%-override')
  }

  const response = await request

  if (!response.data || response.error) {
    trackError(ErrorName.FAILED_TO_FETCH_ACCOUNT_GRAPHS)
    throw new Error('Error getting account graphs')
  }

  return response.data
}

export async function fetchAccountGraphById(
  accountId: string,
  graphId: string
): Promise<AccountGraph> {
  const request = supabase.client
    .from('AccountGraphs')
    .select('*')
    .eq('account_id', accountId)
    .eq('graph_id', graphId)
    .eq('deleted', false)
    .single()

  const response = await request

  if (!response.data || response.error) {
    trackError(ErrorName.FAILED_TO_FETCH_ACCOUNT_GRAPH_BY_ID)
    throw new Error('Error getting account graph by id')
  }

  return response.data
}

export async function createGraph(accountId: string, data: {
  graph_name: AccountGraph["graph_name"]
  matchers: AccountGraph["matchers"]
  graph: AccountGraph["graph"]
  created_by: AccountGraph["created_by"]
}) {
  if(accountId === "ALL")
    throw new Error("Real account ID is required")

  const { error } = await supabase.client
    .from("AccountGraphs")
    .insert({
      ...data,
      account_id: accountId,
      deleted: false
    })
    .eq("account_id", accountId)

  if(error)
    throw new Error("Error creating graph")
}

export async function updateGraph(accountId: string, graphId: string, data: {
  graph_name?: AccountGraph["graph_name"]
  matchers?: AccountGraph["matchers"]
  graph?: AccountGraph["graph"]
}) {
  const { error } = await supabase.client
    .from("AccountGraphs")
    .update({
      ...data,
      updated_at: new Date().toISOString()
    })
    .eq("account_id", accountId)
    .eq("graph_id", graphId)

  if(error)
    throw new Error("Error updating graph")
}

export async function deleteGraph(accountId: string, graphId: string): Promise<void> {
  const { error } = await supabase.client
    .from("AccountGraphs")
    .update({
      deleted: true,
      updated_at: new Date().toISOString()
    })
    .eq("account_id", accountId)
    .eq("graph_id", graphId)

  if(error)
    throw new Error("Error deleting graph")
}
